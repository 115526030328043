.card-prompt {
  @apply mx-[15px] md:mx-0 md:w-[400px] h-[160px];
}
.card-prompt-header-wrapper {
  @apply flex mt-[5px] w-full px-[5px];

  .general {
    @apply h-[80px];
  }
  .new {
    @apply h-60px justify-center;
  }
  .mobile {
    @apply h-[45px] uppercase px-[10px] text-xs font-semibold;
  }
  .card-prompt-header-content {
    @apply flex items-center flex-grow  w-full overflow-hidden text-ellipsis border-b dark:border-gray-1350;
  }
}
.prompt-card-list-item {
  @apply py-[10px] h-auto mb-[20px] hover:cursor-pointer rounded-[5px] w-[300px];
}
.prompt-card-list-item.active {
  @apply dark:bg-gray-1450 bg-blue-1050 border border-blue-1350;
}