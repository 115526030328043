.btn {
  @apply h-[50px] font-light py-[10px] text-white dark:text-black px-[20px] text-[18px] rounded-[5px];
}

.small-btn {
  @apply h-[30px] font-light text-[13px] text-white dark:text-black px-[15px] rounded-[5px];
}

.btn-without-bg {
  @apply h-[40px] font-medium text-[13px] py-[10px] text-blue-1350 px-[15px] border border-blue-1350 rounded-[5px];
  @apply hover:bg-blue-1050 hover:dark:bg-gray-1450 hover:cursor-pointer bg-gray-1175 dark:bg-gray-1600;
}

.btn.default, .small-btn.default {
  @apply bg-blue-1350 cursor-pointer;
  @apply hover:bg-blue-1325 hover:text-white hover:dark:text-black;
}
.btn.inactive {
  @apply rounded-[15px] text-red-1300 border border-red-1300 cursor-pointer bg-white;
  @apply dark:bg-black;
  @apply hover:bg-red-1150 hover:dark:bg-red-1750;
}

.btn.disabled, .small-btn.disabled {
  @apply bg-gray-1225 dark:bg-gray-1250;
}

.form-input.disabled {
  @apply bg-gray-1100 dark:bg-gray-1600;
}

.btn.cancel, .small-btn.cancel {
  @apply text-white md:inline-block;
  @apply bg-red-1250 dark:text-black cursor-pointer;
  @apply hover:bg-red-1200 hover:text-white hover:dark:text-black;
}
.sidebar {
  @apply bg-white-200 dark:bg-gray-1600 md:flex hidden flex-col;
}