.prompt-status-btn {
  @apply md:h-[50px] h-[40px] w-fit rounded-15 border py-[10px] px-[30px] font-medium uppercase;
  &.actif {
    @apply text-blue-1350 border-blue-1350;
  }
  &.inactif {
    @apply text-red-1250 border-red-1250;
  }
  &.deleted {
    @apply text-gray-1200 bg-gray-1175 border-gray-1200 dark:bg-black dark:text-gray-1350 dark:border-gray-1350 line-through;
  }
}
.input-distribution {
  @apply border rounded-[10px] md:h-[50px] h-[40px] w-[60px] p-2 mr-[5px] text-center dark:bg-gray-1600 dark:border-gray-1350;

  &.error {
    @apply border-red-1250 bg-red-1100;
  }
  &.deleted {
    @apply border-gray-1200 bg-gray-1175 line-through dark:bg-black dark:text-gray-1350;
  }
}
.card-prompt-distribution {
  @apply md:w-auto w-full md:h-[160px] h-[140px] hover:cursor-pointer;
  &.deleted {
    @apply bg-gray-1175 dark:bg-black text-gray-1200 dark:text-gray-1350 line-through;
  }
}
.card-prompt-header-distribution {
  @apply py-[20px] pl-30px flex flex-row items-center;
}
.btn-diffusion {
  @apply  h-[40px] font-light uppercase py-[10px] text-white dark:text-black px-[20px] text-[13px];
}
.diffusion-prompts {
  @apply relative h-full overflow-y-auto flex flex-col w-full md:px-60px md:py-[15px] px-15px py-[20px] md:bg-half-vague md:dark:bg-half-vague-dark md:bg-no-repeat;
}
.modal-remove-prompt {
  @apply md:w-[450px] w-full h-auto;
  @apply dark:text-primary-dark;
}
.diffusion-row {
  @apply flex md:flex-row flex-col md:items-center w-full md:mb-0 mb-30px;
}
.prompt-list-header {
  @apply hidden md:flex flex-col text-gray-1325 font-medium text-[16px] mr-30px;

  .trait {
    @apply bg-gray-1100 dark:bg-gray-1350 h-[0.5px] w-full mt-[15px];
  }
}