.message-input-wrapper {
  @apply pb-[10px] flex-none flex self-end flex-col items-center justify-center w-full md:px-0 px-[10px];

  .message-input {
    @apply resize-none bg-white md:rounded-[15px] p-[10px] h-[100px] w-9/12 md:border border-gray-1050;
    @apply dark:bg-black dark:border-gray-1250 focus:outline-none;
  }
}

.message-container {
  @apply flex w-full flex-row md:items-center md:my-[25px] my-[15px] min-h-[65px];

  .message {
    @apply rounded-[30px] p-[20px] w-full;
  }
}

.ia-message {
  .avatar {
    @apply md:mr-[20px] mr-[5px] md:ml-0 ml-[20px];
  }

  .message {
    @apply bg-white w-full md:mr-0 mr-[20px];
    @apply dark:bg-black;
  }
}

.user-message {
  .message {
    @apply bg-gray-1310 md:mr-0 mr-[20px];
    @apply dark:bg-purple-1700;
  }
}

.conversation {
  @apply h-full w-full flex flex-col items-center dark:bg-gray-1600;

  .messages-wrapper {
    @apply flex flex-col items-center w-full flex-grow h-auto;
  }
}
.btn-send-message {
  @apply text-blue-1350 hover:cursor-pointer;
  @apply hover:outline hover:outline-1 hover:outline-blue-1350 hover:bg-blue-1050 dark:hover:bg-gray-1375;
}

.btn-send-wrapper {
  @apply flex flex-col items-center justify-center ml-[20px] rounded-[5px] p-[10px];
}

.var-input-wrapper {
  @apply grid grid-cols-2 gap-x-[40px] gap-y-[20px] min-w-[700px];
}

.var-input {
  @apply bg-white dark:bg-gray-1600 rounded-[5px] p-[10px] w-[300px] h-[40px] border border-gray-1050;
  @apply focus:outline-none;
}

.reload-conversation {
  @apply font-medium uppercase flex flex-row text-blue-1350 h-[30px] items-center justify-center;
  @apply cursor-pointer border border-blue-1350 mb-[10px] w-full md:w-[230px];
}

.documents-wrapper {
  @apply w-full flex flex-row p-[10px];
}

.prompt-document-list-content {
  @apply grid grid-cols-3 gap-3;
}

.upload-doc-label {
  @apply flex flex-row cursor-pointer h-[50px] items-center leading-5;
}

.nbDoc {
  @apply w-[55px] h-[25px] rounded-[30px] bg-blue-1350 text-white flex flex-row items-center justify-center;
}

.with-hover {
  @apply hover:dark:bg-gray-1450 hover:bg-blue-1050 hover:cursor-pointer hover:outline hover:outline-1 hover:outline-blue-1350;
}

.with-bg {
  @apply bg-gray-1100 dark:bg-gray-1800;
}

.card-error {
  @apply bg-red-1100 outline outline-1 outline-red-1300;
}

.card-doc {
  @apply flex flex-row w-[140px] h-[50px] items-center justify-center rounded-[5px];
}

.card-enabled {
  @apply cursor-pointer text-blue-1350 bg-white dark:bg-gray-1425;
}

.card-disabled {
  @apply bg-gray-1200 text-gray-1250;
}