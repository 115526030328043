.btn-publication {
  @apply flex flex-row md:flex-col items-center cursor-pointer md:pr-4 md:border-gray-1125 md:border-r md:hover:text-blue-1350;
}

.publiee {
  @apply text-green-1500;
}

.non-publiee {
  @apply text-red-1125;
}

.btn-option {
  @apply flex flex-col items-center cursor-pointer ml-4 text-gray-1475 hover:text-blue-1350;
}

.app-list-wrapper {
  @apply flex-grow pt-[20px] md:pl-30px pl-[15px] md:w-full h-[calc(100vh-180px)] md:h-[calc(100vh-90px)] overflow-y-auto;
  @apply dark:bg-half-vague-dark bg-half-vague bg-no-repeat;

  .header {
    @apply hidden md:block text-gray-1325 font-medium text-[16px] mr-30px;

    .trait {
      @apply bg-gray-1100 dark:bg-gray-1350 h-[0.5px] w-full my-[15px];
    }
  }
}
.application-edition-page {
  @apply h-full dark:bg-black flex flex-col w-full;
}
.application-navigation {
  @apply bg-white dark:bg-gray-1425 min-h-[80px] md:px-[60px] px-6 z-0 text-gray-1475 dark:text-primary-dark w-full;

  h3 {
    @apply font-medium text-[16px] uppercase;
  }
  .navLink {
    @apply mt-[5px] text-gray-1475 dark:text-primary-dark flex items-center font-light underline hover:text-primary;

    .icon {
      @apply mr-15px;
    }
  }
}
.application-edition-content {
  @apply h-full w-full flex justify-center;
}
.application-form-wrapper {
  @apply mt-[10px] flex flex-col md:w-fit w-full items-center md:px-0 px-[20px];
}
.service-card {
  @apply flex flex-col items-center w-[120px] h-[100px] hover:cursor-pointer;
}
.service-card-list {
  @apply flex flex-row gap-x-[20px];
}
.service-card-title {
  @apply text-[14px] text-center mt-10px leading-5;
}
.app-icon-wrapper {
  @apply flex items-center justify-center rounded-15 bg-white-150 dark:bg-black;
}
.app-empty-icon {
  @apply w-[60px] h-[60px] bg-gray-1050 dark:bg-gray-1600 rounded-full;
}
.app-description-card {
  @apply md:w-[480px] w-full h-auto flex flex-row p-[15px];
}
