@tailwind utilities;
.prompt-card {
  @apply bg-white dark:bg-black rounded-[15px] ;
}
.prompt-card-prompt {
  @apply max-h-[calc(100vh-500px)] min-h-[60px] overflow-y-auto p-[10px];
}

.prompt-header {
  @apply flex flex-row md:h-full items-center w-full;

  .app-title {
    @apply font-semibold text-2xl mt-2 md:text-3xl uppercase mb-2;
  }

  .tags {
    @apply hidden md:flex flex-row text-sm ml-[10px];
  }
}

.prompt-sub-header {
  @apply hidden md:flex items-center md:min-h-[70px] bg-white dark:bg-gray-1425 shadow-bottom;

  h3 {
    @apply font-medium text-[16px];
  }
}

.prompt-sub-header-mobile {
  @apply flex md:hidden items-center h-[120px] flex-col relative;

  .prompt-header {
    @apply w-full px-[25px] py-[10px] justify-between shadow-bottom bg-white dark:bg-gray-1600;
  }

  .menu-wrapper {
    @apply w-full px-[25px] h-[60px] bg-white bg-opacity-50 dark:bg-gray-1425 text-gray-1475 dark:text-blue-1350 shadow-bottom;
  }

  .dropdown-menu {
    @apply z-50 absolute top-[119px] w-[calc(100%-30px)] bg-white dark:bg-gray-1600 text-primary dark:text-gray-1325 rounded-b-[15px] shadow-message-input px-30px dark:shadow-dark-bottom-menu;
  }
}

.prompt-navigation {
  @apply bg-white dark:bg-gray-1425 min-h-[80px] md:px-24 px-6 z-0 text-gray-1375 dark:text-primary-dark shadow-bottom;

  h3 {
    @apply font-medium text-[16px] uppercase;
  }
}

.navLink {
  @apply mt-[5px] md:text-gray-1375 dark:text-primary-dark flex items-center font-light underline hover:text-primary;

  .icon {
    @apply mr-15px;
  }
}

.prompt-content {
  @apply h-full overflow-y-auto md:flex flex-grow w-full;

  .label {
    @apply md:w-1/5 w-2/5;
  }

  .field {
    @apply md:w-8/12 w-full;
  }

  .content-prompt {
    @apply flex-1 flex flex-col md:flex-row md:mb-4 lg:mb-0 w-full;
  }
}

.prompt-page {
  @apply h-full dark:bg-black flex flex-col md:bg-none bg-half-vague bg-no-repeat;
}

.tab-link-prompt {
  @apply cursor-pointer h-full w-[340px] flex flex-col items-center flex-grow;

  .tab {
    @apply flex-grow flex flex-row items-center w-full text-gray-1475 text-opacity-50 dark:text-gray-1325 my-[8px];
    @apply hover:text-blue-1350;
  }
}

.tab-link-prompt.tab-prompt-active {
  .trait {
    @apply h-[2px] bg-white dark:bg-blue-1350 w-[calc(100%-40px)] mt-10px my-10px;
  }

  .tab {
    @apply text-gray-1475 dark:text-blue-1350;
  }
}

.tabs-prompt {
  @apply flex h-full items-center;
}

.content-prompt-fields {
  @apply md:px-60px px-30px flex flex-col pt-30px h-full w-full;
}

.prompt-documents {
  @apply w-full bg-white-200 flex flex-col mt-[15px] md:px-[20px] py-[10px] overflow-y-auto dark:bg-gray-1600 dark:border dark:border-gray-1350;;
}

.modal-prompt-filter {
  @apply w-full h-auto;
  @apply dark:text-primary-dark;

  .card-filter {
    @apply mx-auto px-[10px] flex flex-row items-center w-[280px] h-[70px] border border-gray-1050 dark:border-gray-1700 dark:bg-gray-1600 rounded-15;
  }
}

.modal-activation {
  @apply md:w-[470px] w-full h-auto;
  @apply dark:text-primary-dark;
}
.prompt-list-cards-wrapper {
  @apply h-[calc(100vh-140px)] overflow-y-auto md:min-w-[375px] w-full bg-white-200 dark:dark:bg-gray-1600 flex flex-col;
}
.text-info-prompt {
 @apply bg-white-150 dark:bg-black p-[20px];
}
.status-title {
  @apply font-medium text-[16px] text-gray-1325 mb-10px;
}
.prompt-content-wrapper {
  @apply h-full overflow-y-auto flex flex-grow bg-half-vague bg-no-repeat;
}
.tag-state {
  @apply w-fit flex items-center px-[10px] text-center capitalize font-medium border bg-white dark:bg-gray-1600 rounded-[30px] h-[25px];

  &.actif {
    @apply text-green-1500 border-green-1500;
  }

  &.inactif {
    @apply text-red-1125 border-red-1125;
  }
}
.tag-modele {
  @apply w-fit flex items-center px-[10px] text-center capitalize font-medium border border-white text-white bg-black dark:bg-gray-1600 rounded-[30px] h-[25px];
}
.prompt-card-list-item-content {
  @apply mt-10px ml-[5px] px-[10px] flex flex-row items-center w-full;
}
.prompt-card-list-item-header-wrapper {
  @apply flex flex-row items-center w-full px-[10px];
}
.prompt-card-list-item-header-icon {
  @apply z-40 rounded-[5px] bg-white dark:bg-gray-1600 h-[50px] w-[50px] border border-gray-1050 dark:border-gray-1350 items-center flex justify-center;
}
.prompt-card-list-item-header-title {
  @apply flex items-center w-[225px] ml-[-5px] z-0 text-[16px] font-medium rounded-[5px]
  bg-white dark:bg-gray-1600 h-[40px] border border-gray-1050 dark:border-gray-1350
  px-[15px] overflow-hidden text-ellipsis whitespace-nowrap;
}
.prompt-content-title {
  @apply font-medium text-[16px] text-gray-1325 mb-[20px];
}