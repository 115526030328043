.layout-container {
  @apply text-[13px] font-primary bg-white-150 text-primary flex flex-col h-full;
  @apply dark:text-primary-dark dark:bg-black;
}

.layout-header {
  @apply z-50 bg-white dark:bg-gray-1600 flex justify-between items-center min-h-[70px] shadow-bottom dark:shadow-dark-bottom;
}

.toggle-button-dt {
  @apply md:block hidden;

}

.toggle-button-dt-icon {
  @apply ml-3;
}

.dark-theme-wrapper {
  @apply flex cursor-pointer items-center hover:text-blue-1350 text-gray-1350 dark:text-gray-1250;
}

.outlet-container {
  @apply flex-grow flex text-[13px] bg-white-150 dark:bg-black w-full;
}

.logo-lauria {
  @apply h-[40px] w-[160px] cursor-pointer;
}

.option-header {
  @apply flex flex-row items-center md:mr-60px mr-[20px];
}

.profile-option {
  @apply ml-30px hover:cursor-pointer hover:text-blue-1350 text-gray-1350 dark:text-gray-1250;
}

.sub-header {
  @apply flex flex-col items-center w-full  h-[90px];
  @apply p-[10px];
}

.side-menu {
  @apply md:flex hidden min-w-[200px] h-full bg-white dark:bg-black;

  .menu-item-active {
    @apply bg-blue-1050 border-y border-blue-1350 dark:bg-gray-1450;
  }
}

.logo-lauria-back-mini {
  @apply h-[45px] w-[45px] cursor-pointer ml-[20px];
}
