.card-user {
  @apply flex flex-row items-center ml-30px h-[70px] w-[320px] text-gray-1325 mt-[20px];

  .username-wrapper {
    @apply flex flex-col ml-[10px] ;
  }

  .username {
    @apply font-medium md:text-[16px] dark:text-gray-1250;
  }

  .trait {
    @apply border-b-0.5 border-gray-1100 dark:border-gray-1350 w-[250px] absolute bottom-0;
  }
}

.card-user-mobile {
  @apply md:hidden flex flex-row items-center bg-gray-1325 dark:bg-gray-1425 h-[70px] pl-[15px] text-white dark:text-gray-1250;

  .username {
    @apply ml-[10px] font-semibold md:text-xl dark:text-gray-1250
  }
}

.card-user-mobile select {
  @apply text-gray-1250;
  @apply dark:bg-gray-1600 border-gray-1350;
}