.tags-admin {
  @apply flex-grow h-full flex flex-row self-start overflow-auto;
}
.tag-title {
  @apply md:text-[18px] md:normal-case uppercase font-medium md:my-[30px] pl-[10px] mt-0 flex items-center md:text-gray-1325;
  .tag-icon {
    @apply text-gray-1325 mr-[15px] h-[25px] w-[25px];
  }
}
.tag-title.admin {
  @apply md:my-[20px] mt-15px md:justify-start justify-center;
  @apply md:text-gray-1325 ;
}
.tag-title.options {
  @apply md:h-auto h-[70px] md:my-[10px] md:py-0 py-[15px] md:justify-start justify-center;
}
.tag-title-list {
  @apply flex flex-col pb-15px;
  .trait {
    @apply bg-gray-1100 dark:bg-gray-1350 h-[0.5px] w-full mb-[20px] mr-30px;
  }

  .navigation {
    @apply md:hidden ml-[25px] text-blue-1400 flex flex-row items-center;
    @apply hover:underline cursor-pointer;
  }
}

.tag-name {
  @apply flex items-center rounded-[5px] text-center mr-30px h-[60px] min-w-[220px] bg-white-200;
  @apply whitespace-nowrap text-ellipsis overflow-hidden;
  @apply text-blue-1350 border md:border-blue-1350 border-gray-1050 hover:bg-blue-1050 cursor-pointer;
  @apply hover:dark:bg-gray-1450 dark:bg-black dark:text-gray-1325 dark:border-gray-1350;
  @apply hover:dark:border-blue-1350 dark:hover:text-blue-1350;
}

.tag-name.selected {
  @apply md:bg-blue-1050 md:dark:bg-gray-1600 md:hover:cursor-default md:dark:text-blue-1350 md:dark:border-blue-1350;
}

.tag-button {
  @apply text-[13px] bg-white mx-[2.5px] text-gray-1325 rounded-[25px] px-4 py-1 flex items-center font-light;
  @apply outline outline-0.5 outline-offset-0 outline-gray-1325;
  @apply dark:bg-gray-1600;

  .text-app {
    @apply font-light text-xs;
  }
}
.tags-options-list-wrapper {
  @apply flex flex-col mb-30px h-[calc(100vh-140px)] md:h-full md:overflow-auto overflow-y-scroll;
}
.tags-options-list {
  @apply flex flex-col mb-30px mx-30px md:mx-0 md:pt-0 pt-[5px];
}

.tags-options-wrapper {
  @apply flex-grow overflow-auto md:pl-[20px] md:pr-[10px] w-full md:w-[400px] md:min-w-[430px] bg-white-200 dark:bg-gray-1600 dark:shadow-dark-right z-40 md:h-[calc(100vh-70px)] h-full;
}
.tag-icon {
  @apply text-blue-1400 mr-[40px];
}
.tag-form {
  @apply w-full;
}
.tags-option {
  @apply flex flex-row items-center py-[5px] md:px-[10px] md:rounded-15;
}

.tag-menu {
  .trait {
    @apply bg-gray-1225 dark:bg-gray-1350 h-[0.5px] w-full mb-[20px];
  }
}
.tag-apps-list-wrapper {
  @apply md:h-[calc(100vh-70px)] h-full w-full flex-grow overflow-auto;
  @apply bg-half-vague dark:bg-half-vague-dark bg-no-repeat;
}
.tags-list {
  @apply flex flex-wrap gap-2 mt-[10px];
}
.modal-tag {
  @apply md:w-[440px] w-full h-auto;
  @apply dark:text-primary-dark;
}