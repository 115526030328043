.card-app-content {
  @apply flex ml-[20px] h-full;

  .app-name {
    @apply mb-[5px] w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-medium;
  }
}

.add-app {
  @apply w-[260px] h-[140px] items-center md:flex hidden hover:dark:text-blue-1350 hover:cursor-pointer hover:text-blue-1350;
}

.application-card {
  @apply flex flex-col w-[290px] h-[110px] p-[10px];
}
.app-name {
   @apply text-[13px] font-medium truncate overflow-hidden whitespace-nowrap max-w-full;
 }
.description {
  @apply text-[11px] text-gray-1250 overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.mobile-card {
  @apply min-w-[150px] max-w-[150px] h-[100px] mr-[15px] overflow-hidden;

  .card-app-content {
    @apply flex flex-col h-full;
  }
}