.button-consigne {
  @apply text-primary w-[200px] h-[45px] rounded-[15px] bg-yellow-1050 dark:bg-yellow-1700 flex items-center justify-start pl-[15px];
  @apply hover:outline hover:outline-1 hover:outline-yellow-1500 dark:text-gray-1600 dark:hover:outline-yellow-1500;
}

.prompt-form-fields-content {
  @apply flex-grow md:flex flex-col md:max-w-[450px] w-full md:px-60px px-30px pt-[20px] bg-white-200 dark:bg-gray-1600 h-[calc(100vh-150px)] overflow-auto;
}

.prompt-form-wrapper {
  @apply flex-1 flex md:flex-row flex-col md:mb-0 w-full md:overflow-y-auto h-full;
}

.prompt-field-label {
  @apply h-8 md:h-[45px] flex items-center;
}

.prompt-btn-submit {
  @apply w-full md:w-[180px] md:rounded-[5px] rounded-[0] md:block ml-auto;
}

.prompt-test-wrapper {
  @apply flex flex-col w-full;
}

.prompt-test-fields-wrapper {
  @apply rounded-[5px] py-[10px] px-[20px] flex flex-col items-center w-full bg-white border border-gray-1100 ml-10px;
  @apply dark:bg-gray-1600 dark:border-gray-1350;
}

.btn-launch-test {
  @apply flex flex-row items-center;
}

.test-var {
  @apply w-[180px] h-[40px] bg-gray-1175 dark:bg-gray-1375 rounded-[5px] flex items-center px-[10px];
}

.prompt-test-result {
  @apply dark:border-gray-1350 w-full rounded-[5px] py-[15px] px-[20px] flex flex-col border border-gray-1100 max-h-[450px] overflow-y-auto;
}

.prompt-form-side {
  @apply flex flex-col md:w-full md:block md:h-[calc(100vh-150px)] md:overflow-y-auto px-[20px] bg-white-150 bg-half-vague dark:bg-half-vague-dark bg-no-repeat dark:bg-black;
}

.prompt-test-model-wrapper {
  @apply w-full flex flex-col bg-white dark:bg-gray-1600 border dark:border-gray-1350 border-gray-1100 p-[10px];
}

.answer-parameter {
  @apply bg-white-150 dark:bg-black border border-gray-1100 dark:border-gray-1350 p-[10px] mb-[10px];
}

.prompt-documents-section {
  @apply rounded-[5px] bg-white-150 dark:bg-gray-1425 dark:border-gray-1350 p-[10px] mb-[10px];
}
.reload-variables {
  @apply flex flex-row items-center w-[205px];
}
.prompt-form-test {
  @apply flex flex-row w-full;
}
.prompt-test-side {
  @apply h-[calc(100vh-150px)] w-full items-center flex-[4] px-[10px];
  .messages-wrapper {
    @apply flex flex-col items-center w-full flex-grow h-auto;
  }
}