.profiles-list-wrapper {
  @apply w-full flex-col items-center md:pt-30px;
}

.profile-search {
  @apply flex items-center mb-[20px] h-[50px];
}

.table-profile {
  @apply w-full md:w-4/5;

  th {
    @apply text-left pl-[20px] bg-gray-1325 font-medium text-white h-[60px];
    @apply dark:bg-gray-1425 dark:text-gray-1250;
  }

  td {
    @apply pl-[20px] h-[60px]  border-none;
  }

  tr {
    @apply border-b border-gray-1050 bg-white;
    @apply dark:border-gray-1350 dark:bg-gray-1600;
    @apply hover:bg-blue-1050 hover:dark:bg-gray-1425 hover:cursor-pointer hover:text-blue-1350;
  }
}

.pagination-profile {
  @apply flex flex-row justify-center items-center mt-[20px] gap-x-[10px];

  li {
    @apply hover:bg-blue-1350 hover:text-white hover:dark:text-black;
    @apply flex items-center cursor-pointer rounded-[5px] w-[30px] h-[30px] bg-white border border-gray-1050;
    @apply dark:bg-black dark:border-gray-1250;
  }

  li.selected {
    @apply bg-blue-1350 text-white dark:text-black;
  }

  a {
    @apply mx-auto;
  }
}

.profile-card {
  @apply h-fit w-full md:mt-30px md:w-4/5 mx-auto flex flex-col md:rounded-t-[5px] bg-white dark:bg-gray-1600;
}

.profile-title {
  @apply w-full bg-gray-1325 text-white font-medium h-[60px] flex items-center pl-[20px] md:rounded-t-[5px];
}

.form-content-profile {
  @apply px-[20px] pb-[30px] md:h-auto h-[calc(100vh-290px)] overflow-y-auto;
}

.profile-role-edition {
  @apply w-full border-collapse;

  th {
    @apply text-center px-4 py-2;
  }

  .tag-role {
    @apply border border-gray-1325 rounded-[15px] w-[120px] h-[30px] text-center px-[10px];
    @apply overflow-hidden text-ellipsis whitespace-nowrap leading-[30px];
  }
}

.profile-radio {
  @apply accent-blue-1350 h-[20px] w-[20px] cursor-pointer;
}

.profile-info {
  @apply p-2 max-w-[560px] max-h-[180px] rounded-[5px] bg-white-150 border border-gray-1100;
  @apply dark:bg-gray-1600 dark:border-gray-1350 dark:shadow-dark-right;
}

.profile-buttons {
  @apply md:h-[80px] h-[60px] w-full md:shadow-top text-right self-end pt-[15px] md:pr-[30px];
}

.profile-btn-submit {
  @apply w-full md:w-auto md:rounded-[5px] rounded-[0] md:h-[50px] h-[60px];
}

th.text-left {
  text-align: left !important;
  padding: 0 !important;
}

.table-profile .tag {
  @apply text-[13px] bg-white mx-[2.5px] text-gray-1325 rounded-[25px] px-4 py-1 font-light;
  @apply outline outline-0.5 outline-offset-0 outline-gray-1325;
  @apply dark:bg-gray-1600;
}
